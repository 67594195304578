import React  from 'react';
import './App.css'
import Footer from './components/Footer/Footer'
import Hero from './components/Hero/Hero'
import Join from './components/Join/Join'
import Plans from './components/plans/Plans'
import Programs from './components/programs/Programs'
import Reason from './components/Reason/Reason'
import Testimonials from './components/Testimonials/Testimonials'

function App() {
  // const [count, setCount] = useState(0)

  return (
    <div className="App">
    <Hero/>
    <Programs/>
    <Reason/>
    <Plans/>
    <Testimonials/>
    <Join/>
    <Footer/>
   </div>
  )
}

export default App
